import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageRequest } from '../shared/model/data.table.model';
import { Page } from './model/page';
import { Category } from './model/category';
import { Grades } from './model/grades';
import { Exams } from './model/exam';
import { PlatformSubjects } from './model/platform.subjects';
import { ISubject } from './model/subject';
import { IUnit } from './model/unit';
import { IChapter } from './model/chapter';
import { ITopic } from './model/topic';
import { IDiagnosticSubTopic, ISubtopic } from './model/subtopic';
import { IPageRequestMasterDataService } from './model/page.request.mode';
import { SubjectReport } from './model/subjectReport';
import { SubjectDiagReport } from './model/subjectDiagReport';
import { ServiceType } from './model/service-type';

@Injectable({
    providedIn: 'root',
})
export class MasterDataService {
    constructor(private http: HttpClient) { }

    // categories
    getCategory(
        pageRequest: PageRequest = { size: 100, page: 0 }
    ): Observable<Page<Category>> {
        return this.http.get<Page<Category>>(
            `${environment.masterDataService}/v1/categories`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                    active: true,
                },
            }
        );
    }

    getCategoryById(categoryId: string): Observable<Category> {
        return this.http.get<Category>(
            `${environment.masterDataService}/v1/categories/${categoryId}`
        );
    }

    createCategory(category: Category): Observable<Category> {
        return this.http.post<Category>(
            `${environment.masterDataService}/v1/categories`,
            category
        );
    }

    updateCategory(category: Category): Observable<Category> {
        return this.http.patch<Category>(
            `${environment.masterDataService}/v1/categories/${category.id}`,
            category
        );
    }

    deleteCategory(categoryId: string): Observable<Category> {
        return this.http.delete<Category>(
            `${environment.masterDataService}/v1/categories/${categoryId}`
        );
    }

    //grades
    getGrades(
        pageRequest: IPageRequestMasterDataService = { size: 100, page: 0 }
    ): Observable<Page<Grades>> {
        return this.http.get<Page<Grades>>(
            `${environment.masterDataService}/v1/grades`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                    active: true,
                },
            }
        );
    }

    getGradeyById(gradeId: string): Observable<Grades> {
        return this.http.get<Grades>(
            `${environment.masterDataService}/v1/grades/${gradeId}`
        );
    }

    createGrade(grades: Grades): Observable<Grades> {
        return this.http.post<Grades>(
            `${environment.masterDataService}/v1/grades`,
            grades
        );
    }

    deleteGrade(gradeId: string): Observable<Grades> {
        return this.http.delete<Grades>(
            `${environment.masterDataService}/v1/grades/${gradeId}`
        );
    }

    updateGrade(grades: Grades): Observable<Grades> {
        return this.http.patch<Grades>(
            `${environment.masterDataService}/v1/grades/${grades.id}`,
            grades
        );
    }

    //exams
    getExams(
        pageRequest: IPageRequestMasterDataService = { size: 100, page: 0 }
    ): Observable<Page<Exams>> {
        return this.http.get<Page<Exams>>(
            `${environment.masterDataService}/v1/exams`,
            {
                params: {
                    ...pageRequest,
                    active: true,
                },
            }
        );
    }

    getExamById(examId: string): Observable<Exams> {
        return this.http.get<Exams>(
            `${environment.masterDataService}/v1/exams/${examId}`
        );
    }

    createExam(exams: Exams): Observable<Exams> {
        return this.http.post<Exams>(
            `${environment.masterDataService}/v1/exams`,
            exams
        );
    }

    deleteExam(examId: string): Observable<Exams> {
        return this.http.delete<Exams>(
            `${environment.masterDataService}/v1/exams/${examId}`
        );
    }

    updateExam(exams: Exams): Observable<Exams> {
        return this.http.patch<Exams>(
            `${environment.masterDataService}/v1/exams/${exams.examId}`,
            exams
        );
    }

    //platform-subjects
    getPlatformSubjects(
        pageRequest: PageRequest = { size: 100, page: 0 }
    ): Observable<Page<PlatformSubjects>> {
        return this.http.get<Page<PlatformSubjects>>(
            `${environment.masterDataService}/v1/platformsubjects`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                    active: true,
                },
            }
        );
    }
    getPlatformSubjectById(
        getPlatformSubjectsId: string
    ): Observable<PlatformSubjects> {
        return this.http.get<PlatformSubjects>(
            `${environment.masterDataService}/v1/platformsubjects/${getPlatformSubjectsId}`
        );
    }

    createPlatformSubjec(
        platformSubjects: PlatformSubjects
    ): Observable<PlatformSubjects> {
        return this.http.post<PlatformSubjects>(
            `${environment.masterDataService}/v1/platformsubjects`,
            platformSubjects
        );
    }

    deletePlatformSubjec(platformSubjecId: string): Observable<PlatformSubjects> {
        return this.http.delete<PlatformSubjects>(
            `${environment.masterDataService}/v1/platformsubjects/${platformSubjecId}`
        );
    }
    updatePlatformSubjec(
        platformSubjects: PlatformSubjects
    ): Observable<PlatformSubjects> {
        return this.http.patch<PlatformSubjects>(
            `${environment.masterDataService}/v1/platformsubjects/${platformSubjects.id}`,
            platformSubjects
        );
    }

    // excel-report

    getExcelReportSubjectById(subjectId: string) {
        //let headers = new HttpHeaders();
        return this.http.get<Blob>(
            `${environment.masterDataService}/v1/master-report/download-excel/${subjectId}`, { observe: 'response', responseType: 'blob' as 'json' });
    }

    // upload-updated-excel-report

    uploadExcelData(formData: any): Observable<any> {
        return this.http.patch<any>(`${environment.masterDataService}/v1/subjects/update-master-data`, formData);
    }

    // excel-report-diagnostic

    getExcelReportDiagnostic(subjectId: string) {
        //let headers = new HttpHeaders();
        return this.http.get<Blob>(
            `${environment.masterDataService}/v1/master-report/download-excel-diagnostic/${subjectId}`, { observe: 'response', responseType: 'blob' as 'json' });
    }

    // upload-updated-excel-report-diagnostic

    uploadExcelDiagnostic(formData: any): Observable<any> {
        return this.http.patch<any>(`${environment.masterDataService}/v1/subjects/update-master-data-diagnostic`, formData);
    }

    //subject
    getSubject(
        pageRequest: PageRequest = { size: 100, page: 0 }
    ): Observable<Page<ISubject>> {
        return this.http.get<Page<ISubject>>(
            `${environment.masterDataService}/v1/subjects`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                    active: true
                },
            }
        );
    }

    getSubjectsByGradeId(
        gradeId: any, pageRequest: PageRequest = { size: 100, page: 0 }
    ): Observable<Page<ISubject>> {
        return this.http.get<Page<ISubject>>(
            `${environment.masterDataService}/v1/subjects`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                    gradeId: gradeId,
                    active: true
                },
            }
        );
    }

    getSubjectById(subjectId: string): Observable<ISubject> {
        return this.http.get<ISubject>(
            `${environment.masterDataService}/v1/subjects/${subjectId}`

        );
    }
    getUnitBySubject(subjectCode: string): Observable<IUnit[]> {
        return this.http.get<IUnit[]>(
            `${environment.masterDataService}/v1/subjects/${subjectCode}/units`,
        );
    }

    getChapterBySubjectNUnit(
        subjectCode: string,
        unitCode: string
    ): Observable<IChapter[]> {
        return this.http.get<IChapter[]>(
            `${environment.masterDataService}/v1/subjects/${subjectCode}/units/${unitCode}/chapters`
        );
    }

    getChapterBySubjectNUnitChaapterId(
        subjectId: string,
        unitId: string,
        chapterId: string
    ): Observable<IChapter> {
        return this.http.get<IChapter>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}`
        );
    }

    getTopicBySubjectNUnitNChapter(
        subjectCode: string,
        unitCode: string,
        chapterCode: string
    ): Observable<ITopic[]> {
        return this.http.get<ITopic[]>(
            `${environment.masterDataService}/v1/subjects/${subjectCode}/units/${unitCode}/chapters/${chapterCode}/topics`
        );
    }

    getSubtopicByTopicSubjectNUnitNChapter(
        subjectCode: string,
        unitCode: string,
        chapterCode: string,
        topicCode: string
    ): Observable<ISubtopic[]> {
        return this.http.get<ISubtopic[]>(
            `${environment.masterDataService}/v1/subjects/${subjectCode}/units/${unitCode}/chapters/${chapterCode}/topics/${topicCode}/subtopics`
        );
    }

    createSubject(subject: ISubject): Observable<ISubject> {
        return this.http.post<ISubject>(
            `${environment.masterDataService}/v1/subjects`,
            subject
        );
    }
    updateSubject(subject: ISubject): Observable<ISubject> {
        return this.http.put<ISubject>(
            `${environment.masterDataService}/v1/subjects/${subject.id}`,
            subject
        );
    }

    createUnit(subjectId: string, units: IUnit[]): Observable<IUnit> {
        return this.http.patch<IUnit>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units`,
            units
        );
    }
    // updateSubject(subject: ISubject): Observable<ISubject> {
    //   return this.http.put<ISubject>(
    //     `${environment.masterDataService}/v1/subjects/${subject.id}`,
    //     subject
    //   );
    // }

    deleteSubject(subjectid: string): Observable<ISubject> {
        return this.http.delete<ISubject>(
            `${environment.masterDataService}/v1/subjects/${subjectid}`
        );
    }

    getUnitById(subjectId: string, unitId: string): Observable<IUnit> {
        return this.http.get<IUnit>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}`
        );
    }

    updateUnit(subjectId: string, unit: IUnit): Observable<IUnit> {
        return this.http.put<IUnit>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unit.unitId}`,
            unit
        );
    }

    deleteUnit(subjectId: string, unitId: string): Observable<IUnit> {
        return this.http.delete<IUnit>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}`
        );
    }

    createChapter(
        subjectId: string,
        unitId: string,
        chapters: IChapter[]
    ): Observable<IChapter> {
        return this.http.patch<IChapter>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters`,
            chapters
        );
    }
    updateChapter(
        subjectId: string,
        unitId: string,
        chapter: IChapter
    ): Observable<IChapter> {
        return this.http.put<IChapter>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapter.chapterId}`,
            chapter
        );
    }

    deleteChapter(
        subjectId: string,
        unitId: string,
        chapterId: string
    ): Observable<IChapter> {
        return this.http.delete<IChapter>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}`
        );
    }

    getTopicBySubjectUnitChaapterTopicId(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicId: string
    ): Observable<ITopic> {
        return this.http.get<ITopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicId}`
        );
    }

    createTopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topic: ITopic[]
    ): Observable<ITopic> {
        return this.http.patch<ITopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics`,
            topic
        );
    }


    updateTopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topic: ITopic
    ): Observable<ITopic> {
        return this.http.put<ITopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topic.topicId}`,
            topic
        );
    }



    deleteTopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string
    ): Observable<ITopic> {
        return this.http.delete<ITopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}//topics/${topicID}`
        );
    }


    getSubTopicBySubjectUnitChaapterTopicSubtopicId(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicId: string,
        subtopicId: string,
    ): Observable<ISubtopic> {
        return this.http.get<ISubtopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicId}/subtopics/${subtopicId}`
        );
    }

    deleteSubtopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string,
        subtopicId: string,
    ): Observable<ISubtopic> {
        return this.http.delete<ISubtopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}//topics/${topicID}/subtopics/${subtopicId}`
        );
    }

    createSubtopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string,
        subtopics: ISubtopic[],
    ): Observable<ISubtopic> {
        return this.http.patch<ISubtopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicID}/subtopics`,
            subtopics
        );
    }

    updateSubtopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string,
        subtopic: ISubtopic,
    ): Observable<ISubtopic> {
        return this.http.put<ISubtopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicID}/subtopics/${subtopic.subTopicId}`,
            subtopic
        );
    }

    deleteDiagSubtopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string,
        diagnosticSubTopicId: string,
    ): Observable<IDiagnosticSubTopic> {
        return this.http.delete<IDiagnosticSubTopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}//topics/${topicID}/diagnosticsubtopics/${diagnosticSubTopicId}`
        );
    }

    getDiagSubTopicBySubjectUnitChaapterTopicDiagSubtopicId(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicId: string,
        diagnosticSubTopicId: string,
    ): Observable<IDiagnosticSubTopic> {
        return this.http.get<IDiagnosticSubTopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicId}/diagnosticsubtopics/${diagnosticSubTopicId}`
        );
    }

    createDiagSubtopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string,
        diagsubtopics: IDiagnosticSubTopic[],
    ): Observable<IDiagnosticSubTopic> {
        return this.http.patch<IDiagnosticSubTopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicID}/diagnosticsubtopics`,
            diagsubtopics
        );
    }

    updateDiagSubtopic(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicID: string,
        diagsubtopics: IDiagnosticSubTopic,
    ): Observable<IDiagnosticSubTopic> {
        return this.http.put<IDiagnosticSubTopic>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicID}/diagnosticsubtopics/${diagsubtopics.diagnosticSubTopicId}`,
            diagsubtopics
        );
    }

    getTestData(): Observable<any> {

        const data =
            [
                {
                    "filterHierarchys": [
                        {
                            "subjectId": "T-0606",
                            "selectedUnits": [
                                {
                                    "unitId": "TU-0606A",
                                    "selectedChapters": [
                                        {
                                            "chapterId": "ch2",
                                            "selectedTopics": [
                                                {
                                                    "topicId": "topic001",
                                                    "selectedSubtopics": [
                                                        {
                                                            "subTopicId": "subtopic001",
                                                            "questionCount": 10
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "chapterId": "ch-1",
                                            "selectedTopics": [
                                                {
                                                    "topicId": "t-2",
                                                    "selectedSubtopics": [
                                                        {
                                                            "subTopicId": "subtopic002",
                                                            "questionCount": 11
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "unitId": "TU-0606C",
                                    "selectedChapters": [
                                        {
                                            "chapterId": "ch001",
                                            "selectedTopics": [
                                                {
                                                    "topicId": "topic001",
                                                    "selectedSubtopics": [
                                                        {
                                                            "subTopicId": "subt001",
                                                            "questionCount": 12
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "subjectId": "physics-22",
                            "selectedUnits": [
                                {
                                    "unitId": "unit-id-1-3",
                                    "selectedChapters": [
                                        {
                                            "chapterId": "chpater-id-1-3-1",
                                            "selectedTopics": [
                                                {
                                                    "topicId": "topic-id-1-3-1-1",
                                                    "selectedSubtopics": [
                                                        {
                                                            "subTopicId": "subtopic-id-499",
                                                            "questionCount": 13
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "filterHierarchys": [
                        {
                            "subjectId": "physics-22",
                            "selectedUnits": [
                                {
                                    "unitId": "unit-id-1-3",
                                    "selectedChapters": [
                                        {
                                            "chapterId": "chpater-id-1-3-1",
                                            "selectedTopics": [
                                                {
                                                    "topicId": "topic-id-1-3-1-1",
                                                    "selectedSubtopics": [
                                                        {
                                                            "subTopicId": "subtopic-id-499",
                                                            "questionCount": 14
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ];
        return of(data);
    }

    getSubjectReport(gradeId: string) {
        return this.http.get<SubjectReport[]>(
            `${environment.masterDataService}/v1/master-report/subject-wise`,
            {
                params: {
                    page: Number(0),
                    size: Number(1000),
                    gradeId: gradeId,
                    active: true
                },
            }
        );
    }

    getDiagnosticSubjectReport(gradeId: string) {
        return this.http.get<SubjectDiagReport[]>(
            `${environment.masterDataService}/v1/master-report/diagnostic-report`,
            {
                params: {
                    page: Number(0),
                    size: Number(1000),
                    gradeId: gradeId,
                    active: true
                },
            }
        );
    }

    //service type

    getAllServiceTypeList(pageRequest: PageRequest = { size: 100, page: 0 }): Observable<Page<ServiceType>> {
        return this.http.get<Page<ServiceType>>(`${environment.masterDataService}/v1/serviceTypes`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                },
            })
    }

    createServiceType(serviceTypeDetails: ServiceType): Observable<ServiceType> {
        return this.http.post<ServiceType>(`${environment.masterDataService}/v1/serviceTypes`, serviceTypeDetails);
    }

    getServiceTypeById(serviceID: string): Observable<ServiceType> {
        return this.http.get<ServiceType>(`${environment.masterDataService}/v1/serviceTypes/${serviceID}`);
    }

    updateServiceTypeById(serviceTypeDetails: ServiceType): Observable<ServiceType> {
        return this.http.patch<ServiceType>(`${environment.masterDataService}/v1/serviceTypes/${serviceTypeDetails.serviceID}`, serviceTypeDetails);
    }

    getDiagnosticSubtopics(
        subjectId: string,
        unitId: string,
        chapterId: string,
        topicId: string
    ): Observable<IDiagnosticSubTopic[]> {
        return this.http.get<IDiagnosticSubTopic[]>(
            `${environment.masterDataService}/v1/subjects/${subjectId}/units/${unitId}/chapters/${chapterId}/topics/${topicId}/diagnosticsubtopics`
        );
    }

    getSubjectListByfilterbyidlist(
        subjectId: string,
        showDiagnostic: boolean,
        pageRequest: PageRequest = { size: 100, page: 0 }
    ): Observable<ISubject[]> {
        return this.http.get<ISubject[]>(
            `${environment.masterDataService}/v1/subjects`,
            {
                params: {
                    page: Number(pageRequest.page),
                    size: Number(pageRequest.size),
                    query: 'filterbyidlist',
                    showDiagnostic,
                    showAllHierarchy: true,
                    subjectIds: [subjectId]
                },
            });
    }

    imageUpload(formData: any): Observable<any> {
        return this.http.post<any>(`${environment.masterDataService}/v1/images`, formData);
    }
}
