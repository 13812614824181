import { Page404Component } from 'src/app/auth/page404/page404.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Role } from './core/models/role';

import { EaxmFactorAuthGuard } from 'src/app/auth/auth-guard.service';
import { RandomQuestionDisplayComponent } from './question-management/random-question-display/random-question-display.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [EaxmFactorAuthGuard],
    children: [
      { path: '', redirectTo: '/admin/dashboard/main', pathMatch: 'full' },
      {
        path: 'admin',
        canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'identity',
        canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./identity-management/identity-management.module').then(m => m.IdentityManagementModule),
      },
      {
        path: 'student-management',
        canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./student-management/student-management.module').then(m => m.StudentManagementModule),
      },
      {
        path: 'organization-management',
        // canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./organizatin-management/organization-management.module').then(m => m.OrganizationManagementModule),
      },
      {
        path: 'question-management',
        // canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./question-management/question-management.module').then(m => m.QuestionManagementModule),
      },
      {
        path: 'product-management',
        // canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./product-management/product-management.module').then(m => m.ProductManagementModule),
      },
      {
        path: 'assessment-management',
        // canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./assessment-management/assessment-management.module').then(m => m.AssessmentManagementModule),
      },
      {
        path: 'master-data-management',
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./master-data-management/master-data-management.module').then(m => m.MasterDataManagementModule),
      },
      {
        path: 'coupon-management',
        // canActivate: [EaxmFactorAuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () => import('./coupon-management/coupon-management.module').then(m => m.CouponManagementModule),
      },
      {
        path:'carousel-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./carousel-management/carousel-management.module').then(m =>m.CarouselManagementModule ),
      },
      {
        path:'testimonial-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./testimonial-management/testimonial-management.module').then(m =>m.TestimonialManagementModule ),
      },
      {
        path:'story-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./story-management/story-management.module').then(m =>m.StoryManagementModule ),
      },
      {
        path:'faq-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./faq-management/faq-management.module').then(m =>m.FaqManagementModule )
      },
      {
        path:'webpage-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./webpage-management/webpage-management.module').then(m =>m.WebpageManagementModule ),
      },
      {
        path:'adaptive-practice-settings',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./adaptive-practice-settings/adaptive-practice-settings.module').then(m =>m.AdaptivePracticeSettingsModule),
      },
      {
        path:'b2b-adaptive-practice-settings',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./b2b-adaptive-practice-settings/b2b-adaptive-practice-settings.module').then(m =>m.B2bAdaptivePracticeSettingsModule),
      },
      {
        path:'coin-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./coin-management/coin-management.module').then(m =>m.CoinManagementModule ),
      },
      {
        path:'order-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./order-management/order-management.module').then(m =>m.OrderManagementModule ),
      },
      {
        path:'student-report-management',
        data:{
          role: Role.Admin,
        },
        loadChildren: () => import('./student-report-management/student-report-management.module').then(m =>m.StudentReportManagementModule ),
      },
    ],
  },
  { path: 'printQuestions', component: RandomQuestionDisplayComponent },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
