import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material-module';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatSelectFilterModule } from 'mat-select-filter';
import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FeatherIconsModule,
    NgxMatTimepickerModule.setLocale('en-GB'),
    MatSelectFilterModule,
    AngularEditorModule,
    CKEditorModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    NgxMatTimepickerModule,
    MatSelectFilterModule,
    AngularEditorModule,
    CKEditorModule
  ],
})
export class SharedModule { }
