import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IQuestion } from './model/question';
import { environment } from 'src/environments/environment';
import { PageRequest } from '../shared/model/data.table.model';
import { Page } from './model/page';
import { IPageRequestBulkUploadService, IPageRequestQuestion } from './model/page.request.mode';
import { QuestionReport } from './model/questionReport';
import { QuestionChReport } from './model/questionChReport';
import { QuestionChtaxReport } from './model/questionChtaxReport';
import { TopicalEstimateReport } from './model/topicalEstimate';
import { UntagReport } from './model/untagReport';
import { QuestionDuplicate } from './model/questionDuplicate';
import { MissingTags } from './model/missingTags';
import { TopicalEstimateReportV2 } from './model/topicalEstimateV2';
import { QuestionCountReport } from './model/questionCountReport';
import { SubjectQuestionReport } from './model/subjectQuestionReport';

@Injectable({
    providedIn: 'root'
})
export class QuestionManagementService {

    constructor(
        private http: HttpClient
    ) { }

    createQuestion(question: IQuestion): Observable<Map<string, any>> {
        return this.http.post<Map<string, any>>(`${environment.questionManagementService}/v1/questions`, question);
    }

    getQuestions(pageRequest: IPageRequestQuestion = { size: 10, page: 0 }): Observable<any> {
        return this.http.get<Page<IQuestion>>(`${environment.questionManagementService}/v1/questions`, {
            params: {
                ...pageRequest,
                active: true
            }
        });
    }

    getFilterQuestions(pageRequest: PageRequest = { size: 10, page: 0, active: true }): Observable<any> {
        return this.http.get<Page<IQuestion>>(`${environment.questionManagementService}/v1/questions/filter/question/list`, {
            params: {
                ...pageRequest,
                active: true
            }
        });
    }

    getFilterQuestionsCount(pageRequest: any): Observable<any> {
        return this.http.get<Page<any>>(`${environment.questionManagementService}/v1/questions/filter/question/count`, {
            params: {
                ...pageRequest,
                active: true
            }
          }
        );
      }

    createQuestionReviewLog(questionReview: any): Observable<any> {
        return this.http.post<any>(`${environment.questionManagementService}/v1/questions/question-review-log`, questionReview);
    }

    getQuestionReviewLog(batchId: any, questionId: any): Observable<any> {
        return this.http.get<any>(`${environment.questionManagementService}/v1/questions/question/get-review-log`,{
           params: {batchId: batchId,
            questionId: questionId}
        });
    }

    getQuestionById(questionId: string): Observable<IQuestion> {
        return this.http.get<IQuestion>(`${environment.questionManagementService}/v1/questions/${questionId}`);
    }

    updateQuestion(question: any, action: string = ""): Observable<any> {
        return this.http.patch<any>(`${environment.questionManagementService}/v1/questions/${question.id}`, question, {
            params: { action }
        });
    }

    createBulkUpload(formData: any): Observable<any> {
        return this.http.post<any>(`${environment.questionManagementService}/v1/bulk-upload-requests`, formData);
    }

    getBulkUploadList(pageRequest: IPageRequestBulkUploadService = { size: 10, page: 0 }): Observable<Page<any>> {
        return this.http.get<Page<IQuestion>>(`${environment.questionManagementService}/v1/bulk-upload-requests`, {
            params: {
                ...pageRequest
            }
        });
    }

    getBulkUploadDetailsById(bulkUploadId: string): Observable<any> {
        return this.http.get<any>(`${environment.questionManagementService}/v1/bulk-upload-requests/${bulkUploadId}`);
    }

    deleteBulkUploadDetailsById(batchIds: string): Observable<any> {
        return this.http.delete<any>(
           `${environment.questionManagementService}/v1/bulk-upload-requests/delete-batches`,{params:{ids:batchIds}}
        );
      }

    updateBulkUploadRequest(bulkRequestId: string, action: string = ""): Observable<any> {
        return this.http.patch<any>(`${environment.questionManagementService}/v1/bulk-upload-requests/${bulkRequestId}`, {}, {
            params: { action }
        });
    }

    changeStateRequest(questionId: string, linkText: string, state: string): Observable<any> {
        return this.http.patch<any>(`${environment.questionManagementService}/v1/questions/question/change-state`, {
            questionId: questionId,
            publishIndicator: linkText,
            state: state
        });
    }
    
    getQuestionTemplate() {
        return this.http.get<Blob>(`${environment.questionManagementService}/v1/bulk-upload-templates/questionTemplate`, { observe: 'response', responseType: 'blob' as 'json' });
    }

    getGeneralinstructions() {
        return this.http.get<Blob>(`${environment.questionManagementService}/v1/bulk-upload-templates/generalInstructions`, { observe: 'response', responseType: 'blob' as 'json' });
    }

    getQuestionReport(formJson: any) {
        return this.http.get<QuestionReport[]>(
            `${environment.questionManagementService}/v1/questions-report/subject-wise`,
            {
                params: formJson,
            }
        );
    }

    // getMissingTags(formJson: any) {
    //     return this.http.get<MissingTags[]>(
    //         `${environment.questionManagementService}/v1/questions-report/missing-tags`,
    //         {
    //             params: formJson,
    //         }
    //     );
    // }

    getMissingTags(
        pageRequest: PageRequest = { size: 10, page: 0, active: true }
    ): Observable<Page<MissingTags[]>> {
        return this.http.get<Page<MissingTags[]>>(
            `${environment.questionManagementService}/v1/questions-report/missing-tags`,
            {
                params: {
                   ...pageRequest,
                },
            }
        );
    }

    getQuestionSubDiffi(formJson: any) {
        return this.http.get<QuestionReport[]>(
            `${environment.questionManagementService}/v1/questions-report/difficulty`,
            {
                params: formJson
            }
        );
    }

    getQuestionSubDiffiTax(formJson: any) {
        return this.http.get<QuestionReport[]>(
            `${environment.questionManagementService}/v1/questions-report/difficulty/taxonomy`,
            {
                params: formJson
            }
        );
    }

    getQuestionChReport(formJson: any) {
        return this.http.get<QuestionChReport[]>(
            `${environment.questionManagementService}/v1/questions-report/chapter`,
            { params: formJson }
        );
    }

    getQuestionChtaxReport(formJson: any) {
        return this.http.get<QuestionChtaxReport[]>(
            `${environment.questionManagementService}/v1/questions-report/chapter/taxonomy`,
            { params: formJson }
        );
    }

    getTopicalEstimateReport(formJson: any) {
        return this.http.get<TopicalEstimateReport[]>(
            `${environment.questionManagementService}/v1/questions-report/topical-estimate`,
            { params: formJson }
        );
    }

    getQuestionsByBachId(formData: any) {

        return this.http.post<any>(`${environment.questionManagementService}/v1/assessmentquestions`, formData);
    }

    getUntagReport(formJson: any) {
        return this.http.get<UntagReport[]>(
            `${environment.questionManagementService}/v1/questions-report/untagged-bulk-question`,
            { params: formJson }
        );
    }

    getUntagReportCount(formJson: any) {
        return this.http.get<Page<IQuestion>>(
            `${environment.questionManagementService}/v1/questions-report/batch-questions`,
            { params: formJson }
        );
    }

    getQuestionsCount(): Observable<any> {
        return this.http.get<any>(`${environment.questionManagementService}/v1/questions-report/count`, {
          params: {
            // productType: "",
            // state : ["PUBLISHED"],
            // isActive : "" // this between date range please pass to YES
          },
        })
    }


    getQuestionsSimilarity(
        pageRequest: PageRequest = { size: 10, page: 0, active: true }
    ): Observable<Page<QuestionDuplicate>> {
        return this.http.get<Page<QuestionDuplicate>>(
            `${environment.questionManagementService}/v1/questions-similarity/duplicate-question-list`,
            {
                params: {
                    ...pageRequest
                },
            }
        );
    }


    getQuestionDuplicate(formData: any) {

        return this.http.post<any>(`${environment.questionManagementService}/v1/questions-similarity/duplicate-question`, formData);
    }

    getTopicalEstimateReportV2(formJson: any) {
        return this.http.get<TopicalEstimateReportV2[]>(
            `${environment.questionManagementService}/v1/questions-report/topical-estimate-new`,
            { params: formJson }
        );
    }

    getQuestionCountReport(formJson: any) {
        return this.http.get<QuestionCountReport[]>(
            `${environment.questionManagementService}/v1/questions-report/ques-count-report`,
            { params: formJson }
        );
    }

    getSubjectQuestionReport(formJson: any) {
        return this.http.get<SubjectQuestionReport[]>(
            `${environment.questionManagementService}/v1/questions-report/subject-questionwise-report`,
            { params: formJson }
        );
    }

    getFilterQuesReport(attributeName: string, attributeValue: string, formJson: any): Observable<any> {
        const { attributeName: _, attributeValue: __, ...params } = formJson;
        return this.http.get<any>(
            `${environment.questionManagementService}/v1/questions/filter-ques/${attributeName}/${attributeValue}?action=filter-type`,
            { params }
        );       
    }


    getRandomQuestions(
        pageRequest:any
    ): Observable<any> {
        return this.http.get<any>(
            `${environment.questionManagementService}/v1/questions/question/get-random-ques`,
            {
                params: {
                    ...pageRequest
                },
            }
        );
    }

    
}
 