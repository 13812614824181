import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MasterDataService } from '../services/masterdata.service';
import { Subject, takeUntil } from 'rxjs';
import { AdaptiveSettingsService } from '../services/adaptive-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddaptiveSettings } from '../services/model/addaptive-settings';
import { Category } from '../services/model/category';
import { Page } from '../services/model/page';
import { Exams } from '../services/model/exam';

@Component({
  selector: 'app-adaptive-practice-settings',
  templateUrl: './adaptive-practice-settings.component.html',
  styleUrls: ['./adaptive-practice-settings.component.scss']
})
export class AdaptivePracticeSettingsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  public examList: any[] = []
  breadscrums = [
    {
      title: 'Adaptive Practice Settings',
      items: [],
      active: '',
    },
  ];
  difficultyLevelList: any[] = ['EASY', 'MODERATE', 'DIFFICULT'];
  taxonomyList = ['REMEMBERING', 'UNDERSTANDING', 'APPLYING', 'ANALYSING', 'EVALUATING'];
  adaptiveSettingsForm: FormGroup;
  isVisibleButton = false;
  isVisiableUpdateButton = false;
  isVisiableSaveButton = false;
  addaptiveSettingsId: any = "";
  diffecultyLevelInNumber: any;
  taxonomyInNumber: any;
  isdisablePublishButton = true;
  adaptiveSettingsInformation: any = null;
  categoryList!: Category[];

  constructor(
    public _formBuilder: FormBuilder,
    public masterDataService: MasterDataService,
    public adaptiveSettingsService: AdaptiveSettingsService,
    private _snackBar: MatSnackBar
  ) {
    this.adaptiveSettingsForm = this._formBuilder.group({
      examCode: '',
      category: '',
      addaptiveSettings: new FormArray([
      ], this.isDisplayNameDuplicate())
    })
  }

  ngOnInit() {
    this.masterDataService.getCategory().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.categoryList = res.content;
    })
    // this.masterDataService.getExams({ page: 0, size: 2000 }).pipe(takeUntil(this.destroyed$)).subscribe((exams: any) => {
    //   this.examList = exams.content;
    //   console.log(this.examList)
    // })
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  newAddaptiveSettingsItems(): FormGroup {
    return this._formBuilder.group({
      diffeculty: new FormControl('', Validators.required),
      taxonomy: new FormControl({ value: '', disabled: true }, Validators.required),
      diplayName: new FormControl({ value: null, disabled: true }, Validators.required),
      levelValue: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/), Validators.min(1)]),
      levelOrder: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.min(1)]),
      totalQuestion: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.min(1)]),
      levelUp: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.min(1)]),
      levelDown: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.min(1)]),
    })
  }

  addaptiveSettingsItems(): FormArray {
    return this.adaptiveSettingsForm.get('addaptiveSettings') as FormArray;
  }

  addAddaptiveSettingsItems() {
    this.addaptiveSettingsItems().push(this.newAddaptiveSettingsItems());
  }

  removeAddaptiveSettingsItems(itemIndex: number) {
    this.addaptiveSettingsItems().removeAt(itemIndex);
  }

  diffecultyLevelSelectionChange(diffecultyLevel, itemIndex) {
    const addaptiveSettingsItemsGroup = this.addaptiveSettingsItems().at(itemIndex) as FormGroup;
    addaptiveSettingsItemsGroup.controls['taxonomy'].enable();
    if (addaptiveSettingsItemsGroup.controls['taxonomy'].value) {
      let displayName = this.createDisplayName(diffecultyLevel, addaptiveSettingsItemsGroup.controls['taxonomy'].value);
      addaptiveSettingsItemsGroup.controls['diplayName'].patchValue(displayName);
      addaptiveSettingsItemsGroup.controls['levelValue'].patchValue(`${this.diffecultyLevelInNumber}.${this.taxonomyInNumber}`);
    }
  }

  taxonomySelectionChange(taxonomy, itemIndex) {
    const addaptiveSettingsItemsGroup = this.addaptiveSettingsItems().at(itemIndex) as FormGroup;
    let displayName = this.createDisplayName(addaptiveSettingsItemsGroup.controls['diffeculty'].value, taxonomy);
    addaptiveSettingsItemsGroup.controls['diplayName'].enable();
    addaptiveSettingsItemsGroup.controls['levelValue'].enable();
    addaptiveSettingsItemsGroup.controls['levelOrder'].enable();
    addaptiveSettingsItemsGroup.controls['totalQuestion'].enable();
    addaptiveSettingsItemsGroup.controls['levelUp'].enable();
    addaptiveSettingsItemsGroup.controls['levelDown'].enable();
    addaptiveSettingsItemsGroup.controls['diplayName'].patchValue(displayName);
    addaptiveSettingsItemsGroup.controls['levelValue'].patchValue(`${this.diffecultyLevelInNumber}.${this.taxonomyInNumber}`);
  }

  createDisplayName(diffecultyLevel, taxonomy) {
    this.diffecultyLevelInNumber = this.difficultyLevelList.findIndex(ele => ele == diffecultyLevel) + 1;
    this.taxonomyInNumber = this.taxonomyList.findIndex(ele => ele == taxonomy) + 1;
    return `${diffecultyLevel}-${taxonomy}(${this.diffecultyLevelInNumber}.${this.taxonomyInNumber})`;
  }

  onExamSelectionChange(event) {
    let selectedExam = event.value;
    this.adaptiveSettingsService.getAdaptivePracticeByExamId(selectedExam, this.adaptiveSettingsForm.controls['category'].value).pipe(takeUntil(this.destroyed$)).subscribe((adaptiveSettingsData: AddaptiveSettings) => {
      this.addaptiveSettingsId = adaptiveSettingsData?.id;
      this.addaptiveSettingsItems().clear();
      this.isdisablePublishButton = adaptiveSettingsData?.addaptiveSettings.length > 0 ? false : true;
      this.adaptiveSettingsInformation = adaptiveSettingsData?.id && adaptiveSettingsData?.addaptiveSettings.length > 0 ? adaptiveSettingsData : null;
      console.log(this.adaptiveSettingsInformation)
      if (adaptiveSettingsData?.addaptiveSettings.length > 0) {
        this.isVisibleButton = true;
        this.isVisiableUpdateButton = true;
        this.isVisiableSaveButton = false;
        let adaptiveSettingsItem = adaptiveSettingsData.addaptiveSettings.map(items => {
          this.addaptiveSettingsItems().push(this.newAddaptiveSettingsItems());
          this.addaptiveSettingsItems().enable();
          return items;
        });
        this.adaptiveSettingsForm.patchValue({
          addaptiveSettings: adaptiveSettingsItem
        });
      } else {
        this.isVisibleButton = true;
        this.isVisiableSaveButton = true;
        this.isVisiableUpdateButton = false;
        this.addaptiveSettingsItems().push(this.newAddaptiveSettingsItems());
      }
    })
  }

  saveAdaptiveSettingsFormData() {
    this.adaptiveSettingsForm.value.addaptiveSettings = this.createPayload(this.adaptiveSettingsForm.value.addaptiveSettings);
    if (!this.addaptiveSettingsId) {
      this.adaptiveSettingsService.createAdaptivePracticeBy({ ...this.adaptiveSettingsForm.value, state: 'DRAFT', categoryCode: this.adaptiveSettingsForm.controls['category'].value }).pipe(takeUntil(this.destroyed$)).subscribe((resp: any) => {
        this.addaptiveSettingsId = resp.id;
        this.onExamSelectionChange({ value: this.adaptiveSettingsForm.value.examCode })
        this._snackBar.open('Addaptive settings saved successfully', '', {
          duration: 5000,
          panelClass: ['ef-success-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }, (error) => {
        this._snackBar.open('Addaptive settings saved failed', '', {
          duration: 5000,
          panelClass: ['ef-danger-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
    } else {
      console.log("edit call");
      this.adaptiveSettingsService.editAdaptivePracticeSettings({ ...this.adaptiveSettingsForm.value, id: this.addaptiveSettingsId, state: 'DRAFT' }).pipe(takeUntil(this.destroyed$)).subscribe((resp: any) => {
        //this.addaptiveSettingsId = resp.id;
        console.log(resp)
        this.adaptiveSettingsInformation = resp;
        this._snackBar.open('Addaptive practice settings edited successfully', '', {
          duration: 5000,
          panelClass: ['ef-success-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }, (error) => {
        this._snackBar.open('Addaptive practice settings edited failed', '', {
          duration: 5000,
          panelClass: ['ef-danger-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
    }
  }

  createPayload(adaptiveSettingItems) {
    console.log(adaptiveSettingItems)
    return adaptiveSettingItems.map(item => {
      let obj = {
        diffeculty: item.diffeculty,
        taxonomy: item.taxonomy,
        diplayName: item.diplayName,
        levelValue: parseFloat(item.levelValue),
        levelOrder: parseInt(item.levelOrder),
        totalQuestion: parseInt(item.totalQuestion),
        levelUp: parseInt(item.levelUp),
        levelDown: parseInt(item.levelDown)
      };
      return obj
    })
  }

  publishAdaptivePracticeSettings() {
    this.adaptiveSettingsService.publishAdaptivePracticeSettings(this.addaptiveSettingsId).pipe().subscribe(ele => {
      this.onExamSelectionChange({ value: this.adaptiveSettingsForm.value.examCode })
      this._snackBar.open('Addaptive practice settings published successfully', '', {
        duration: 5000,
        panelClass: ['ef-success-snackbar'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }, (error) => {
      this._snackBar.open('Addaptive practice settings published failed', '', {
        duration: 5000,
        panelClass: ['ef-danger-snackbar'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    })
  }

  disablePublishButton() {
    console.log(this.addaptiveSettingsItems().length, this.adaptiveSettingsInformation?.addaptiveSettings?.length)
    return this.adaptiveSettingsInformation?.addaptiveSettings?.length !== this.addaptiveSettingsItems().length
  }

  allButtonRemovedIfStateIsPublished() {
    return this.adaptiveSettingsInformation?.state === 'PUBLISHED'
  }

  getExamByCategory(event: any) {
    this.masterDataService
      .getExams({ page: 0, size: 2000, categoryId: event.value })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((exams: Page<Exams>) => {
        this.examList = exams.content;
      });

  }


  isDisplayNameDuplicate() {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls
          .map(control => control.value);
        const diplayName = totalSelected.map(value => value.diplayName)
        const hasDuplicate = diplayName.some((name, index) =>{ 
          if(diplayName.indexOf(name, index + 1) != -1){
            let indexFormArray = diplayName.indexOf(name, index + 1);
            this.addaptiveSettingsItems().at(indexFormArray).get('diplayName')?.setErrors({duplicate: true});
            return true;
          }else{
            return false;
          }
        });

        // console.log(hasDuplicate)
        return hasDuplicate ? { duplicate: true } : null;
      }

      throw new Error('formArray is not an instance of FormArray');
    };
    return validator;
  }

}
