<!-- my-loader.component.html -->
<div class="progress-loader" [hidden]="!loading">
    <div class="loading-spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
        <!-- <span class="loading-message">Please wait...</span> -->
    </div>
</div>

<div class="progress-loader" [hidden]="!customisedloading">
    <div class="loading-spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
</div>
