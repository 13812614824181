<div class="content">
       
        <!-- <div class="row"> -->
        <div class="ef-bulk-upload-review">
          
            <div class="row">
                <!-- Instruction -->
                <div class="row border border-2 m-3 p-3" [id]="'question'.concat(setQuestionId(qDataIndex))"
                    *ngFor="let items of questionList ,let qDataIndex=index">
                    <div class="col-12 px-0">
                        <!-- <div class="d-flex flex-column ">
                            <span class="question-header">Instruction</span>
                            <app-content-panel>
                                <div class="ef-q-content readonly-content" [ngClass]="EFUtil.contentPanelStyles(ins)"
                                    [ngStyle]="{'color': '#'+ins.color}"
                                    *ngFor="let ins of items.instructions; let insIndex = index">
                                    <span class="text-dark fw-bolder" *ngIf="isVisible(ins, 'TEXT')"
                                        id="{{'ins-content-'+this.setQuestionId(qDataIndex)+ '-' +(insIndex+1)}}"></span>
                                    <img *ngIf="isVisible(ins, 'IMAGE')"
                                        [ngStyle]="{width: ins.width, height: ins.height}"
                                        [src]="EFUtil.renderImage(ins)" />
                                    <span *ngIf="isVisible(ins, 'LIST_PARA')" class="ef-q-content readonly-content">
                                        <ol [style.list-style]="EFUtil.setListStyle(ins.listParagraph)"
                                            class="ef-ordered-list">
                                            <li *ngFor="let l of ins.listContent;let lIdx = index">
                                                <div class="ef-q-content readonly-content"
                                                    [ngClass]="EFUtil.contentPanelStyles(lp)"
                                                    *ngFor="let lp of l.listContent; let lpIdx = index">
                                                    <span *ngIf="isVisible(lp, 'TEXT')" [ngStyle]="{color:'#'+lp.color}"
                                                        id="{{'ins-list-content-qd'+this.setQuestionId(qDataIndex)+ '-i' +(insIndex+1)+'-l'+(lIdx+1)+'-lp'+(lpIdx+1)}}"></span>
                                                    <img *ngIf="isVisible(lp, 'IMAGE')"
                                                        [ngStyle]="{width: lp.width, height: lp.height}"
                                                        [src]="EFUtil.renderImage(lp)" />
                                                </div>
                                            </li>
                                        </ol>
                                    </span>
                                </div>
                            </app-content-panel>
                        </div> -->
                    </div>

                    <div class="col-12 mt-2 py-4 bg-light">
                        <!-- Comprehension -->
                        <div *ngIf="items.comprehension && items.comprehension.length">
                            <span class="question-header">Comprehension</span>
                            <app-content-panel>
                                <div class="ef-q-content readonly-content" [ngClass]="EFUtil.contentPanelStyles(cc)"
                                    [ngStyle]="{'color': '#'+cc.color}"
                                    *ngFor="let cc of items.comprehension; let ccIndex = index">
                                    <span *ngIf="isVisible(cc, 'TEXT')"
                                        id="{{'comprehension-content-'+this.setQuestionId(qDataIndex)+ '-' +(ccIndex+1)}}"></span>
                                    <img *ngIf="isVisible(cc, 'IMAGE')" [ngStyle]="{width: cc.width, height: cc.height}"
                                        [src]="EFUtil.renderImage(cc)" />
                                    <span *ngIf="isVisible(cc, 'LIST_PARA')" class="ef-q-content readonly-content">
                                        <ol [style.list-style]="EFUtil.setListStyle(cc.listParagraph)"
                                            class="ef-ordered-list">
                                            <li *ngFor="let l of cc.listContent;let lIdx = index">
                                                <div class="ef-q-content readonly-content"
                                                    [ngClass]="EFUtil.contentPanelStyles(lp)"
                                                    *ngFor="let lp of l.listContent; let lpIdx = index">
                                                    <span *ngIf="isVisible(lp, 'TEXT')" [ngStyle]="{color:'#'+lp.color}"
                                                        id="{{'comp-list-content-qd'+this.setQuestionId(qDataIndex)+ '-cmp' +(ccIndex+1)+'-l'+(lIdx+1)+'-lp'+(lpIdx+1)}}"></span>
                                                    <img *ngIf="isVisible(lp, 'IMAGE')"
                                                        [ngStyle]="{width: lp.width, height: lp.height}"
                                                        [src]="EFUtil.renderImage(lp)" />
                                                </div>
                                            </li>
                                        </ol>
                                    </span>
                                </div>
                            </app-content-panel>
                        </div>
                        <div class="question-container">
                            <div class="row">
                                <div class="col-11">
                                    <!-- Question -->
                                    <app-content-panel>
                                        <div class="ef-q-content readonly-content"
                                            [ngClass]="EFUtil.contentPanelStyles(qc)"
                                            *ngFor="let qc of items.questionContents; let quesIndex = index">
                                            <span class="q-mark" *ngIf="quesIndex==0">Q{{setQuestionId(qDataIndex)}}.
                                            </span>
                                            <span *ngIf="isVisible(qc, 'TEXT')" [ngStyle]="{color:'#'+qc.color}"
                                                id="{{'ques-content-'+this.setQuestionId(qDataIndex)+ '-' +(quesIndex+1)}}"></span>
                                            <img *ngIf="isVisible(qc, 'IMAGE')"
                                                [ngStyle]="{width: qc.width, height: qc.height}"
                                                [src]="EFUtil.renderImage(qc)" />
                                            <span *ngIf="isVisible(qc, 'LIST_PARA')"
                                                class="ef-q-content readonly-content">
                                                <ol [style.list-style]="EFUtil.setListStyle(qc.listParagraph)"
                                                    class="ef-ordered-list">
                                                    <li *ngFor="let l of qc.listContent;let lIdx = index">
                                                        <div class="ef-q-content readonly-content"
                                                            [ngClass]="EFUtil.contentPanelStyles(lp)"
                                                            *ngFor="let lp of l.listContent; let lpIdx = index">
                                                            <span *ngIf="isVisible(lp, 'TEXT')"
                                                                [ngStyle]="{color:'#'+lp.color}"
                                                                id="{{'q-list-content-qd'+this.setQuestionId(qDataIndex)+ '-q' +(quesIndex+1)+'-l'+(lIdx+1)+'-lp'+(lpIdx+1)}}"></span>
                                                            <img *ngIf="isVisible(lp, 'IMAGE')"
                                                                [ngStyle]="{width: lp.width, height: lp.height}"
                                                                [src]="EFUtil.renderImage(lp)" />
                                                        </div>
                                                    </li>
                                                </ol>
                                            </span>
                                            <span *ngIf="isVisible(qc, 'TABLE')">
                                                <table class="content-tbl">
                                                    <thead>
                                                        <tr>
                                                            <th *ngFor="let tblH of qc.dataTableContent.tbHeaders">
                                                                {{tblH.tableHeader}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let tbRow of qc.dataTableContent.tbRows; let tbRowIndex = index">
                                                            <td *ngFor="let tbleRow of tbRow.tableCells; let tbleRowIndex = index"
                                                                [ngStyle]="{'text-align': EFUtil.setTblHorizontalAlignment(tbleRow.cellContents)}">
                                                                <app-content-panel>
                                                                    <div class="ef-q-content readonly-content"
                                                                        [ngClass]="EFUtil.contentPanelStyles(tblRCel)"
                                                                        *ngFor="let tblRCel of tbleRow.cellContents;let tblCelIndex=index;">
                                                                        <span *ngIf="isVisible(tblRCel, 'TEXT')"
                                                                            id="{{'q-tbl-content-qd'+this.setQuestionId(qDataIndex)+ '-q' +(quesIndex+1)+'-tbr'+(tbRowIndex+1)+'-tbr'+(tbleRowIndex+1)+'-tbrc'+(tblCelIndex+1)}}"></span>
                                                                        <img *ngIf="isVisible(tblRCel, 'IMAGE')"
                                                                            [ngStyle]="{width: tblRCel.width, height: tblRCel.height}"
                                                                            [src]="EFUtil.renderImage(tblRCel)" />
                                                                    </div>
                                                                </app-content-panel>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </span>
                                        </div>
                                    </app-content-panel>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- Answer -->
                    <div class="col-12 px-0 mt-2 mx-0">
                        <app-content-panel>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                [(ngModel)]="items.correctAnsTxt">
                                <div class="row mx-0">
                                    <div class="col-6 option-container"
                                        *ngFor="let answer of items.answerChoices; let ansIndex=index">
                                        <div class="answer-container position-relative">
                                            <mat-radio-button class="example-radio-button option-marker"
                                                [value]="answer.id">
                                            </mat-radio-button>
                                            <div class="ms-4">
                                                <div class="ef-q-content readonly-content"
                                                    [ngClass]="EFUtil.contentPanelStyles(ac)"
                                                    [ngStyle]="{'color': '#'+ac.color}"
                                                    *ngFor="let ac of answer.answerChoiceContents; let acIndex = index">
                                                    <span *ngIf="isVisible(ac, 'TEXT')"
                                                        id="{{'ans-content-'+this.setQuestionId(qDataIndex) +'-'+(ansIndex+1)+'-'+(acIndex+1)}}"></span>
                                                    <img *ngIf="isVisible(ac, 'IMAGE')"
                                                        [ngStyle]="{width: ac.width, height: ac.height}"
                                                        [src]="EFUtil.renderImage(ac)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </app-content-panel>
                    </div>


                    <!-- // For displaying answer -->


                    <!-- Solution -->
                    <div class="col-12 mb-3">
                        <div class="d-flex flex-column ">
                            <span class="question-header">Solution</span>
                            <app-content-panel>
                                <div class="ef-q-content readonly-content" [ngClass]="EFUtil.contentPanelStyles(sol)"
                                    [ngStyle]="{'color': '#'+sol.color}"
                                    *ngFor="let sol of items.solution; let solIndex=index">
                                    <span *ngIf="isVisible(sol, 'TEXT')"
                                        id="{{'sol-content-'+this.setQuestionId(qDataIndex)+'-'+(solIndex+1)}}"></span>
                                    <img *ngIf="isVisible(sol, 'IMAGE')"
                                        [ngStyle]="{width: sol.width, height: sol.height}"
                                        [src]="EFUtil.renderImage(sol)" />
                                    <span *ngIf="isVisible(sol, 'LIST_PARA')" class="ef-q-content readonly-content">
                                        <ol [style.list-style]="EFUtil.setListStyle(sol.listParagraph)"
                                            class="ef-ordered-list">
                                            <li *ngFor="let l of sol.listContent;let lIdx = index">
                                                <div class="ef-q-content readonly-content"
                                                    [ngClass]="EFUtil.contentPanelStyles(lp)"
                                                    *ngFor="let lp of l.listContent; let lpIdx = index">
                                                    <span *ngIf="isVisible(lp, 'TEXT')" [ngStyle]="{color:'#'+lp.color}"
                                                        id="{{'sol-list-content-qd' + this.setQuestionId(qDataIndex) + '-sl' + (solIndex + 1) + '-l' + (lIdx + 1)+'-lp'+(lpIdx+1)}}"></span>
                                                    <img *ngIf="isVisible(lp, 'IMAGE')"
                                                        [ngStyle]="{width: lp.width, height: lp.height}"
                                                        [src]="EFUtil.renderImage(lp)" />
                                                </div>
                                            </li>
                                        </ol>
                                    </span>
                                </div>
                            </app-content-panel>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
</div>