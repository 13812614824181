import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AddaptiveSettings } from './model/addaptive-settings';

@Injectable({
  providedIn: 'root'
})
export class AdaptiveSettingsService {

  constructor(
    private http: HttpClient
  ) { }

  getAdaptivePracticeByExamId(examCode: string, categoryCode: string): Observable<AddaptiveSettings> {
    return this.http.get<AddaptiveSettings>(`${environment.masterDataService}/v1/addaptiveSettings?examCode=${examCode}&categoryCode=${categoryCode}`)
  }

  createAdaptivePracticeBy(adaptivePracticeData:AddaptiveSettings): Observable<any>{
    return this.http.post<any>(`${environment.masterDataService}/v1/addaptiveSettings`,adaptivePracticeData)
  }

  editAdaptivePracticeSettings(adaptivePracticeData:AddaptiveSettings):Observable<any>{
    return this.http.put<any>(`${environment.masterDataService}/v1/addaptiveSettings/${adaptivePracticeData.id}`,adaptivePracticeData)
  }

  publishAdaptivePracticeSettings(adaptivePracticeSettingsId:string):Observable<any>{
    return this.http.patch<any>(`${environment.masterDataService}/v1/addaptiveSettings/${adaptivePracticeSettingsId}?action=publish`,{})
  }
}
