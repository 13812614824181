<section class="content">
    <div class="content-block ">
        <div class="breadcrumb">
            <span class="breadcrumb-text">{{breadscrums[0].title}}</span>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-card class="w 100 p-3">
                    <form [formGroup]="adaptiveSettingsForm" class="mb-2">
                        <div class="mt-4 row p-2">
                            <div class="col-4">
                                <div class="row flex-column ">
                                    <span class="lebel-style">Select a category</span>
                                    <mat-form-field appearance="outline" class="mt-1">
                                        <mat-label>Select</mat-label>
                                        <mat-select formControlName="category"
                                            (selectionChange)="getGradeByCategory($event)">
                                            <mat-option *ngFor="let category of categoryList" [value]="category.id">
                                                {{category.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row flex-column ">
                                    <span class="lebel-style">Select an grade</span>
                                    <mat-form-field appearance="outline" class="mt-1">
                                        <mat-label>Select</mat-label>
                                        <mat-select formControlName="gradeId"
                                            (selectionChange)="onGradeSelectionChange($event)">
                                            <mat-option *ngFor="let grade of gradeList" [value]="grade.id">
                                                {{grade.gradeDesc}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <!-- <span class="float-end" [hidden]="allButtonRemovedIfStateIsPublished()">
                                    <button mat-raised-button color="primary" [disabled]="adaptiveSettingsInformation === null || disablePublishButton()" (click)="publishAdaptivePracticeSettings()">Publish</button>
                                </span> -->
                            </div>
                        </div>
                        <div class="container-fluid mt-2" formArrayName="addaptiveSettings">
                            <div class="row mt-2 align-items-center " [hidden]="!isVisibleButton">
                                <div class="col-2 lebel-style">B2B Adaptive Settings Items</div>
                                <!-- <div class="col-2">
                                    <span class="float-end" [hidden]="allButtonRemovedIfStateIsPublished()"><button mat-raised-button color="primary" type="button"
                                            (click)="addAddaptiveSettingsItems()">Add Items</button></span>
                                </div> -->
                                <div class="col-2">
                                    <span class="float-end"><button mat-raised-button color="primary" type="button"
                                            (click)="addAddaptiveSettingsItems()">Add Items</button></span>
                                </div>
                            </div>
                            <div class="mt-2 border border-dark p-2 d-flex flex-column"
                                *ngFor="let item of addaptiveSettingsItems().controls; let itemIndex=index">
                                <div class="row">
                                    <div class="col-12 text-danger w-100 text-justify fw-bold fs-5"
                                        *ngIf="item.get('diplayName').errors?.duplicate">
                                        This combination of b2b adaptive settings is already exist
                                    </div>
                                    <div class="col-12">
                                        <!-- <span title="Remove Item" class="float-end" [hidden]="(addaptiveSettingsItems().length===1) || allButtonRemovedIfStateIsPublished()">
                                            <img src="../../../assets/images/bulk-upload/remove_icon.svg" alt=""
                                                (click)="removeAddaptiveSettingsItems(itemIndex)">
                                            <button mat-mini-fab color="primary"
                                                (click)="removeAddaptiveSettingsItems(itemIndex)">
                                                <mat-icon>remove</mat-icon>
                                            </button>
                                        </span> -->
                                        <span title="Remove Item" class="float-end"
                                            [hidden]="(addaptiveSettingsItems().length===1)">
                                            <img src="../../../assets/images/bulk-upload/remove_icon.svg" alt=""
                                                (click)="removeAddaptiveSettingsItems(itemIndex)">
                                        </span>
                                    </div>
                                </div>
                                <div class="row mt-1" [formGroupName]="itemIndex">
                                    <div class="col-2">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Diffeculty</mat-label>
                                            <mat-select formControlName="diffeculty"
                                                (selectionChange)="diffecultyLevelSelectionChange($event.value,itemIndex)">
                                                <mat-option *ngFor="let difficultyLevel of difficultyLevelList"
                                                    [value]="difficultyLevel">
                                                    {{difficultyLevel}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-hint
                                                *ngIf="item.get('diffeculty').hasError('required') && item.get('diffeculty').touched"
                                                class="text-danger font-14">
                                                Required
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Taxonomy</mat-label>
                                            <mat-select formControlName="taxonomy"
                                                (selectionChange)="taxonomySelectionChange($event.value,itemIndex)">
                                                <mat-option *ngFor="let taxonomy of taxonomyList" [value]="taxonomy">
                                                    {{taxonomy}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-hint
                                                *ngIf="item.get('taxonomy').hasError('required') && item.get('taxonomy').touched"
                                                class="text-danger font-14">
                                                Required
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Display Name</mat-label>
                                            <input matInput formControlName="diplayName" readonly>
                                            <!-- <mat-hint class="text-danger w-100 text-justify"
                                                *ngIf="item.get('diplayName').errors?.duplicate">
                                                Duplicate value
                                            </mat-hint> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Level Value</mat-label>
                                            <input matInput formControlName="levelValue" placeholder="Numeric" readonly>
                                            <mat-hint class="text-danger w-100 text-justify"
                                                *ngIf="item.get('levelValue').hasError('pattern')">
                                                Number only
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Level Order</mat-label>
                                            <input matInput formControlName="levelOrder" placeholder="Numeric">
                                            <mat-hint class="text-danger w-100 text-justify"
                                                *ngIf="item.get('levelOrder').hasError('pattern')">
                                                Number only
                                            </mat-hint>
                                            <mat-hint
                                                *ngIf="item.get('levelOrder').hasError('required') && item.get('levelOrder').touched"
                                                class="text-danger font-14">
                                                Required
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Total Question</mat-label>
                                            <input matInput formControlName="totalQuestion" placeholder="Numeric">
                                            <mat-hint class="text-danger w-100 text-justify"
                                                *ngIf="item.get('totalQuestion').hasError('pattern')">
                                                Number only
                                            </mat-hint>
                                            <mat-hint
                                                *ngIf="item.get('totalQuestion').hasError('required') && item.get('totalQuestion').touched"
                                                class="text-danger font-14">
                                                Required
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Level Up</mat-label>
                                            <input matInput formControlName="levelUp" placeholder="Numeric">
                                            <mat-hint class="text-danger w-100 text-justify"
                                                *ngIf="item.get('levelUp').hasError('pattern')">
                                                Number only
                                            </mat-hint>
                                            <mat-hint
                                                *ngIf="item.get('levelUp').hasError('required') && item.get('levelUp').touched"
                                                class="text-danger font-14">
                                                Required
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Level Down</mat-label>
                                            <input matInput formControlName="levelDown" placeholder="Numeric">
                                            <mat-hint class="text-danger w-100 text-justify"
                                                *ngIf="item.get('levelDown').hasError('pattern')">
                                                Number only
                                            </mat-hint>
                                            <mat-hint
                                                *ngIf="item.get('levelDown').hasError('required') && item.get('levelDown').touched"
                                                class="text-danger font-14">
                                                Required
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- <div class="me-2">
                        <span class="float-end" [hidden]="!isVisibleButton || allButtonRemovedIfStateIsPublished()"><button mat-raised-button color="primary"
                                [disabled]="adaptiveSettingsForm.invalid" (click)="saveAdaptiveSettingsFormData()"><span
                                    [hidden]="!isVisiableSaveButton">Save</span><span
                                    [hidden]="!isVisiableUpdateButton">Update</span></button></span>
                    </div> -->
                    <div class="me-2">
                        <span class="float-end" [hidden]="!isVisibleButton"><button mat-raised-button color="primary"
                                [disabled]="adaptiveSettingsForm.invalid" (click)="saveAdaptiveSettingsFormData()"><span
                                    [hidden]="!isVisiableSaveButton">Save</span><span
                                    [hidden]="!isVisiableUpdateButton">Update</span></button></span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</section>