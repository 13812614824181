import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules

  {
    path: '/admin/dashboard/main',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  //Organization Module
  {
    path: '',
    title: 'MENUITEMS.ORGANIZATION.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'business',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/organization-management/organization',
        title: 'Organization',
        iconType: 'material-icons-two-tone',
        icon: 'supervised_user_circle',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  //Identity Module

  {
    path: '',
    title: 'MENUITEMS.IDENTITY_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'perm_identity',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/identity/users',
        title: 'MENUITEMS.IDENTITY_MANAGEMENT.LIST.USERS',
        iconType: 'material-icons-two-tone',
        icon: 'supervised_user_circle',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/identity/roles',
        title: 'MENUITEMS.IDENTITY_MANAGEMENT.LIST.ROLES',
        iconType: 'material-icons-two-tone',
        icon: 'manage_accounts',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENT_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'perm_identity',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/student-management/students',
        title: 'MENUITEMS.STUDENT_MANAGEMENT.LIST.USERS',
        iconType: 'material-icons-two-tone',
        icon: 'supervised_user_circle',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.QUESTION_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'cloud_upload',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/question-management/bulk-upload-landing',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_BULK_UPLOAD',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/question-management/question-creation',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_CREATION',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/question-management/question-duplicate',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_DUPLICATE',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/question-management/questions',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/question-management/questions-filter-list',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_FILTER_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/question-management/random-questions',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.RANDOM_QUESTIONS',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      // {
      //   path: '/question-management/batch-delete',
      //   title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_BATCH_DELETE',
      //   iconType: 'material-icons-two-tone',
      //   icon: 'cloud_upload',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
      {
        path: '',
        title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.TEXT',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [{
          path: '/question-management/questions-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_SUB_REPORT',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/questions-missing-tags',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_MISSING_TAGS',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/questions-ch-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_CH_REPORT',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/questions-chtax-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_CH_TAX',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/topical-estimate-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_TOPICAL',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/topical-estimate-report-v2',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_TOPICAL_V2',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/ques-count-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_COUNT',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/subject-ques-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.QUESTION_SUBJECTWISE_REPORT',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        },
        {
          path: '/question-management/untagged-bulk-report',
          title: 'MENUITEMS.QUESTION_MANAGEMENT.LIST.QUESTION_REPORT.LIST.UNTAGGED_BULK_UPLOAD_REPORT',
          iconType: 'material-icons-two-tone',
          icon: 'cloud_upload',
          class: 'ml-menu-2',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: [''],
          submenu: [],
        }],
      }
    ],
  },
  {
    path: '/coupon-management',
    title: 'MENUITEMS.COUPON_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'call_to_action',
    // icon: 'monetization_on',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: []
  },
  // Product Management
  {
    path: '',
    title: 'MENUITEMS.PRODUCT_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'store',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/product-management/product-list',
        title: 'MENUITEMS.PRODUCT_MANAGEMENT.LIST.PRODUCT_CREATION',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/product-management/product-session-list',
        title: 'MENUITEMS.PRODUCT_MANAGEMENT.LIST.SESSION_CREATION',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/product-management/report',
        title: 'MENUITEMS.PRODUCT_MANAGEMENT.LIST.REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  // Assessment Management
  {
    path: '',
    title: 'MENUITEMS.ASSESSMENT_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'assessment',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/assessment-management/assessment-list',
        title: 'MENUITEMS.ASSESSMENT_MANAGEMENT.LIST.ASSESSMENT_CREATION',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/assessment-management/assessment-template/assessment-list',
        title: 'MENUITEMS.ASSESSMENT_MANAGEMENT.LIST.ASSESSMENT_TEMPLATE',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  //adaptive practice settings
  {
    path: '/adaptive-practice-settings',
    title: 'MENUITEMS.ADAPTIVE_PRACTICE_SETTINGS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'settings_applications',
    // icon: 'monetization_on',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: []
  },
   //b2b adaptive practice settings
   {
    path: '/b2b-adaptive-practice-settings',
    title: 'MENUITEMS.B2B_ADAPTIVE_PRACTICE_SETTINGS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'settings_applications',
    // icon: 'monetization_on',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: []
  },
  // master-data-management
  {
    path: '',
    title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'dns',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/master-data-management/categories',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.CATEGORY',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/grades',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.GRADES',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/platform-subjects',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.PLATFORM_SUBJECTS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/subject',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.SUBJECTS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/exams',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.EXAMS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/report',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
      ,
      {
        path: '/master-data-management/diag-report',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.SUB_DIA_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/services',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.SERVICES',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/master-data-management/excel-report',
        title: 'MENUITEMS.MASTER_DATA_MANAGEMENT.LIST.EXCEL_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // carousel-management
  {
    path: '',
    title: 'MENUITEMS.CAROUSEL_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'view_carousel',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/carousel-management/carousel',
        title: 'MENUITEMS.CAROUSEL_MANAGEMENT.LIST.CAROUSEL',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // testimonial-management
  {
    path: '',
    title: 'MENUITEMS.TESTIMONIAL_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'reviews',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/testimonial-management/testimonial',
        title: 'MENUITEMS.TESTIMONIAL_MANAGEMENT.LIST.TESTIMONIAL',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // order-management
  {
    path: '',
    title: 'MENUITEMS.ORDER_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'reviews',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/order-management/order',
        title: 'MENUITEMS.ORDER_MANAGEMENT.LIST.ORDER',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/order-management/invoice',
        title: 'MENUITEMS.ORDER_MANAGEMENT.LIST.INVOICE',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // story-management
  {
    path: '',
    title: 'MENUITEMS.STORY_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'auto_stories',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/story-management/story',
        title: 'MENUITEMS.STORY_MANAGEMENT.LIST.STORY',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/story-management/type-management/type',
        title: 'MENUITEMS.STORY_MANAGEMENT.LIST.TYPE_LIST',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // coin-management
  {
    path: '',
    title: 'MENUITEMS.COIN_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'auto_stories',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/coin-management/member-levels',
        title: 'MENUITEMS.COIN_MANAGEMENT.LIST.MEMBER_LEVELS_LIST',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/coin-management/coin-event',
        title: 'MENUITEMS.COIN_MANAGEMENT.LIST.EVENT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/coin-management/coin-summary-report',
        title: 'MENUITEMS.COIN_MANAGEMENT.LIST.COIN_SUMMARY_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/coin-management/coin-add-logs',
        title: 'MENUITEMS.COIN_MANAGEMENT.LIST.COIN_ADD_LOGS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/coin-management/coin-subtract-logs',
        title: 'MENUITEMS.COIN_MANAGEMENT.LIST.COIN_SUBTRACT_LOGS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/coin-management/voucher-claim-report',
        title: 'MENUITEMS.COIN_MANAGEMENT.LIST.VOUCHER_CLAIM_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // student-report-management
  {
    path: '',
    title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'reviews',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/student-report-management/student-report',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.STUDENT_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/student-report-management/stu-assessment-report',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.STU_ASSESSMENT_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/student-report-management/mock-analysis-report',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.MOCK_ANALYSIS_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/student-report-management/student-mockwise-feedback-report',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.STUDENT_MOCKWISE_FEEDBACK_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/student-report-management/student-practice-feedback-report',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.STUDENT_PRACTICE_FEEDBACK_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/student-report-management/report-question',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.REPORT_QUESTION',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/student-report-management/student-activity-report',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.STUDENT_ACTIVITY_REPORT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },{
        path: '/student-report-management/student-info',
        title: 'MENUITEMS.STUDENT_REPORT_MANAGEMENT.LIST.SEARCH_STUDENT',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ]
  },
  // faq-management
  {
    path: '',
    title: 'MENUITEMS.FAQ_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'question_answer',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/faq-management/faq',
        title: 'MENUITEMS.FAQ_MANAGEMENT.LIST.FAQ',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/faq-management/ps-faq',
        title: 'MENUITEMS.FAQ_MANAGEMENT.LIST.PS_FAQ',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ]
  },
  // webpage-management
  {
    path: '',
    title: 'MENUITEMS.WEBPAGE_MANAGEMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'language',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/webpage-management/type-management/type',
        title: 'MENUITEMS.WEBPAGE_MANAGEMENT.LIST.TYPE_LIST',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      // {
      //   path: '/webpage-management/webpage-creation',
      //   title: 'MENUITEMS.WEBPAGE_MANAGEMENT.LIST.WEBPAGE_CREATION',
      //   iconType: 'material-icons-two-tone',
      //   icon: 'cloud_upload',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },
      {
        path: '/webpage-management/webpages',
        title: 'MENUITEMS.WEBPAGE_MANAGEMENT.LIST.WEB_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  }
];