import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MasterDataService } from 'src/app/services/masterdata.service';
import { IAnswer } from 'src/app/services/model/answer';
import { Category } from 'src/app/services/model/category';
import { IChapter } from 'src/app/services/model/chapter';
import { IContent } from 'src/app/services/model/content';
import { Exams } from 'src/app/services/model/exam';
import { PlatformSubjects } from 'src/app/services/model/platform.subjects';
import { IQuestion } from 'src/app/services/model/question';
import { QuestionChReport } from 'src/app/services/model/questionChReport';
import { ISubject } from 'src/app/services/model/subject';
import { ISubtopic } from 'src/app/services/model/subtopic';
import { ITopic } from 'src/app/services/model/topic';
import { IUnit } from 'src/app/services/model/unit';
import { QuestionManagementService } from 'src/app/services/question.management.service';
import { EFUtil } from 'src/app/util/ef.util';
import { QuestionTransformUtil } from 'src/app/util/question-transform.util';



@Component({
  selector: 'app-random-question-display',
  templateUrl: './random-question-display.component.html',
  styleUrls: ['./random-question-display.component.scss']
})
export class RandomQuestionDisplayComponent {

  public EFUtil = EFUtil;
  duplicateResults: IQuestion[] = [];
  dataSource!: MatTableDataSource<any>;
  isLoading = false;
  showMessageIfTableIsBlank = false;
  subjectData: any = {}
  questionListData!: IQuestion[];
  questionList!: IQuestion[];
  configForm!: FormGroup;
  subTopicList!: ISubtopic[];
  topicList!: ITopic[];
  public subjectQsList: QuestionChReport[] = [];
  public examList: Exams[] = [];
  defaultView: string = "list"
  public categoryId: string = '';
  public examId: string = '';
  public gradeId: string = '';
  public unitId: string = '';
  public questionNumber: string = '';
  public chapterId: string = '';
  public gradeDesc: string = '';
  public diagnostic: string = '';
  public reportType: string = 'subTopic';
  public form: FormGroup = Object.create({});
  public categoryData: Category[] = [];
  public examData: Exams[] = [];
  public subjectsData: ISubject[] = [];
  public unitsData: IUnit[] | undefined;
  public chaptersData: IChapter[] | undefined;
  public topicsData: ITopic[] | undefined;
  private destroyed$ = new Subject<void>();
  public subjectId: string = '';
  public subjectList: ISubject[] = [];
  public unitList: IUnit[] = [];
  public chapterList: IChapter[] = [];
  public plateformSubjectData: PlatformSubjects[] = [];
  public topicId: string = '';
  public pageRequest: any = {};
  totalItems!: number;
  payLoad:any = {};



  constructor(
    private fb: FormBuilder,
    public masterDataService: MasterDataService,
    public snackBar: MatSnackBar,
    public router: Router,
    private activeRoute: ActivatedRoute,
    public questService: QuestionManagementService,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private location: Location,
  ) {
  }


  setQuestionId(qDataIndex: number) {
    return (qDataIndex + 1);
  }

  ngOnInit(): void {
    this.masterDataService.getCategory().pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.categoryData = data.content;
    });

    console.warn("Printing")

    this.activeRoute.queryParams.subscribe(params => {
      console.log(params);
      this.payLoad = params;
      this.fetchQuestions();
    });
  }


  fetchQuestions() {
    this.questService.getRandomQuestions(this.payLoad)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: IQuestion[]) => {
        this.questionListData = res;
        console.warn("Printing res", res)
        this.questionList = res;
        console.warn("questionList from random question", this.questionList);
        this.questionList = this.questionList.map((quesObj: any) => QuestionTransformUtil.transform(quesObj));
        this.questionList.map((quesObj: any, qDataIndex: number) => {
          if (quesObj.answerChoices) {
            quesObj.correctAnsTxt = quesObj.answerChoices.find(ac => ac.correct)?.id;
          } else {
            quesObj.correctAnsTxt = "";
          }


          if (quesObj.instructions && quesObj.instructions.length > 0) {
            quesObj.instructions.map((insc: IContent, insId: number) => {
              if (insc.contentType === 'TEXT') {
                EFUtil.renderMathJaxElementBySelectorId(insc, 'ins-content-' + this.setQuestionId(qDataIndex) + '-' + (insId + 1));
              } else if (insc.contentType === 'LIST_PARA' && insc?.listContent.length > 0) {
                insc.listContent.map((l: IContent, lIdx: number) => {
                  if (l.listContent) {
                    l.listContent.map((lp: IContent, lpIdx: number) => {
                      EFUtil.renderMathJaxElementBySelectorId(lp, 'ins-list-content-qd' + this.setQuestionId(qDataIndex) + '-i' + (insId + 1) + '-l' + (lIdx + 1) + '-lp' + (lpIdx + 1));
                    });
                  }
                });
              }
            });
          }

          if (quesObj.comprehension && quesObj.comprehension.length > 0) {
            quesObj.comprehension.map((cc: IContent, ccId: number) => {
              if (cc.contentType === 'TEXT') {
                EFUtil.renderMathJaxElementBySelectorId(cc, 'comprehension-content-' + this.setQuestionId(qDataIndex) + '-' + (ccId + 1));
              } else if (cc.contentType === 'LIST_PARA' && cc?.listContent.length > 0) {
                cc.listContent.map((l: IContent, lIdx: number) => {
                  if (l.listContent) {
                    l.listContent.map((lp: IContent, lpIdx: number) => {
                      EFUtil.renderMathJaxElementBySelectorId(lp, 'comp-list-content-qd' + this.setQuestionId(qDataIndex) + '-cmp' + (ccId + 1) + '-l' + (lIdx + 1) + '-lp' + (lpIdx + 1));
                    });
                  }
                });
              }
            });
          }


          // For Question 
          if (quesObj.questionContents && quesObj.questionContents.length > 0) {
            quesObj.questionContents.map((qc: IContent, qcId: number) => {
              if (qc.contentType === 'TEXT') {
                EFUtil.renderMathJaxElementBySelectorId(qc, 'ques-content-' + this.setQuestionId(qDataIndex) + '-' + (qcId + 1));
              } else if (qc.contentType === 'LIST_PARA' && qc?.listContent.length > 0) {
                qc.listContent.map((l: IContent, lIdx: number) => {
                  if (l.listContent) {
                    l.listContent.map((lp: IContent, lpIdx: number) => {
                      EFUtil.renderMathJaxElementBySelectorId(lp, 'q-list-content-qd' + this.setQuestionId(qDataIndex) + '-q' + (qcId + 1) + '-l' + (lIdx + 1) + '-lp' + (lpIdx + 1));
                    });
                  }
                });
              } else if (qc.contentType === 'TABLE' && qc?.dataTableContent?.tbRows) {
                qc.dataTableContent.tbRows.map((tbRow: any, tbRowIndex: number) => {
                  tbRow.tableCells.map((tbleRow: any, tbleRowIndex: number) => {
                    tbleRow.cellContents.map((tblRCel: any, tblCelIndex: number) => {
                      EFUtil.renderMathJaxElementBySelectorId(tblRCel, 'q-tbl-content-qd' + this.setQuestionId(qDataIndex) + '-q' + (qcId + 1) + '-tbr' + (tbRowIndex + 1) + '-tbr' + (tbleRowIndex + 1) + '-tbrc' + (tblCelIndex + 1));
                    });
                  });
                });
              }
            });
          }


          if (quesObj.answerChoices && quesObj.answerChoices.length > 0) {
            quesObj.answerChoices.map((ac: IAnswer, acId: number) => {
              ac.answerChoiceContents.map((acc: IContent, accId: number) => {
                EFUtil.renderMathJaxElementBySelectorId(acc, 'ans-content-' + this.setQuestionId(qDataIndex) + '-' + (acId + 1) + '-' + (accId + 1));
              });
            });
          }

          // For Solution 
          if (quesObj.solution && quesObj.solution.length > 0) {
            quesObj.solution.map((solc: IContent, solId: number) => {
              if (solc.contentType === 'TEXT') {
                EFUtil.renderMathJaxElementBySelectorId(solc, 'sol-content-' + this.setQuestionId(qDataIndex) + '-' + (solId + 1));
              } else if (solc.contentType === 'LIST_PARA' && solc?.listContent.length > 0) {
                solc.listContent.map((l: IContent, lIdx: number) => {
                  if (l.listContent) {
                    l.listContent.map((lp: IContent, lpIdx: number) => {
                      EFUtil.renderMathJaxElementBySelectorId(lp, 'sol-list-content-qd' + this.setQuestionId(qDataIndex) + '-sl' + (solId + 1) + '-l' + (lIdx + 1) + '-lp' + (lpIdx + 1));
                    });
                  }
                });
              }
            });
          }


          const unitsData = this.subjectsData.find(
            (so: ISubject) => so.id === quesObj.subjectCode
          )?.units;

          const chaptersData = unitsData?.find(
            (uo: IUnit) => uo.unitId === quesObj.unitCode
          )?.chapters;

          const topicData = chaptersData?.find(
            (ch: IChapter) => ch?.chapterId === quesObj.chapterCode
          )?.topics;

          const subTopicsData = topicData?.find(
            (to: ITopic) => to.topicId === quesObj.topicCode
          )?.subTopics;

          const subTopicName = subTopicsData?.find(
            (sb: ISubtopic) => sb.subTopicId === quesObj.subTopicCode
          )?.displayNames[0].name;
          return { ...quesObj, subTopicName }
        });
      });
  }


  getFetchQuestions(payLoad: any) {
    this.isLoading = true;
    this.questService.getRandomQuestions(payLoad)
      .subscribe({
        next: (response) => {
          console.warn(response);
          if (response.content && response.content.length > 0) {
            this.showMessageIfTableIsBlank = false;
            this.totalItems = response.totalElements;
          } else {
            this.showMessageIfTableIsBlank = true;
            this.showNoDataSnackbar();
          }
          this.isLoading = false;
        },
        error: (err) => {
          console.warn("Error fetching Random:", err);
          this.isLoading = false;
          this.snackBar.open('Random list fetch failed ', 'close', {
            duration: 5000,
            panelClass: ['ef-danger-snackbar'],
            horizontalPosition: 'center',
          });
        }
      });
  }



  showNoDataSnackbar() {
    this.snackBar.open('No data found ', 'close', {
      duration: 5000,
      panelClass: ['ef-danger-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }



  isVisible(c: any, type: string) {
    return c.contentType == type;
  }




  validateHTML(htmlString: string) {
    const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;

    return regexForHTML.test(htmlString);
  }



  onPageChange(event: any) {
    this.getFetchQuestions(this.pageRequest);
  }

}
