import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { B2BAddaptiveSettings } from './model/b2b-addaptive-settings';

@Injectable({
  providedIn: 'root'
})
export class B2BAdaptiveSettingsService {

  constructor(
    private http: HttpClient
  ) { }

  getAdaptivePracticeByGradeId(gradeId: string, categoryCode: string): Observable<B2BAddaptiveSettings> {
    return this.http.get<B2BAddaptiveSettings>(`${environment.masterDataService}/v1/b2b-addaptiveSettings?gradeId=${gradeId}&categoryCode=${categoryCode}`)
  }

  createAdaptivePracticeBy(b2badaptivePracticeData:B2BAddaptiveSettings): Observable<any>{
    return this.http.post<any>(`${environment.masterDataService}/v1/b2b-addaptiveSettings`,b2badaptivePracticeData)
  }

  editAdaptivePracticeSettings(b2badaptivePracticeData:B2BAddaptiveSettings):Observable<any>{
    return this.http.put<any>(`${environment.masterDataService}/v1/b2b-addaptiveSettings/${b2badaptivePracticeData.id}`,b2badaptivePracticeData)
  }

  publishAdaptivePracticeSettings(b2badaptivePracticeSettingsId:string):Observable<any>{
    return this.http.patch<any>(`${environment.masterDataService}/v1/b2b-addaptiveSettings/${b2badaptivePracticeSettingsId}?action=publish`,{})
  }
}
